<template>
    <div style="display: flex;justify-content: center;align-items: center">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "LeisureGameIframView",
        data() {
            return {
                isShow: true,
            }
        },
        methods: {
            setIsShow() {
                this.isShow = !this.isShow
            }
        }
    }
</script>

<style scoped>
    .showFram {
        display: block !important;
    }
</style>