<template>
    <div class="button_group">
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/powerball', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 파워볼</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/powersadali', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 파워사다리</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/speedkino', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 스피트키노</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/kinosadali', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 키노사다리</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/fx1m', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> FX게임</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/bet365_premiership', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 가상축구</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/bet365_goldenhill', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 개경주</router-link>
        <router-link tag="button" class="btn03 bw4"  :to="{path:'/leisure/namedsnail', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 네임드 게임</router-link>
    </div>
</template>
<script>
    export default {
        name: "LeisureGameLinks",

    }
</script>

<style scoped>

</style>